import UFormItem from '@/components/UFormItem'
import { useStore } from '@/store'
import { http } from '@/utils'
import { Button, Card, Col, DatePicker, Descriptions, DescriptionsProps, Drawer, Flex, Form, InputNumber, Row, Space, Spin, Switch, Table, TableColumnsType, Typography, message } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

const { Text } = Typography

interface TabDetailProps {
  batchId: number
}

interface BatchStockListType {
  id: number
  code: string
  code_name: string
  pre_fin_report_at: string
  fin_report_at: string
  main_biz: string
}

const TabDetail: React.FC<TabDetailProps> = ({ batchId }) => {
  const [descItems, setDescItems] = useState<DescriptionsProps['items']>([])
  const [stock, setStockList] = useState<{ list: BatchStockListType[] }>({
    list: [],
  })
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { batchStore } = useStore()
  const { updateDate } = batchStore

  useEffect(() => {
    const getBatchDetail = async () => {
      const res = await http.post(`/v1/batch/detail`, { id: batchId })
      const { name, type_cn, status_cn, remark, created_at, position_qty } = res.data.batch_info
      const newDescItems: DescriptionsProps['items'] = [
        {
          key: '1',
          label: '名称',
          children: name,
        },
        {
          key: '2',
          label: '类型',
          children: type_cn,
        },
        {
          key: '3',
          label: '状态',
          children: status_cn,
        },
        {
          key: '4',
          label: '创建时间',
          children: created_at,
        },
        {
          key: '5',
          label: '股票数量',
          children: position_qty,
        },
        {
          key: '6',
          label: '备注',
          children: remark,
        },
      ]
      setDescItems(newDescItems)
      setStockList({
        list: res.data.batch_stock_info,
      })
      form.setFieldsValue({
        start_at: dayjs(created_at, 'YYYY-MM-DD'),
      })
      setSelectedRowKeys(res.data.batch_stock_info.map((item: { id: number }) => item.id))
      updateDate(dayjs(created_at).format('YYYY-MM-DD 08:00:00'), dayjs().format('YYYY-MM-DD 08:00:00'))
      setLoading(false)
    }
    if (batchId) {
      setLoading(true)
      getBatchDetail()
    }
  }, [batchId, form])

  interface DataType {
    id: number
    code: string
    code_name: string
    pre_fin_report_at: string
    fin_report_at: string
    main_biz: string
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '代码',
      dataIndex: 'code',
      render: (text: string) => <Text copyable={{ tooltips: false }}>{text}</Text>,
    },
    {
      title: '名称',
      dataIndex: 'code_name',
    },
    {
      title: '预告日期',
      dataIndex: 'pre_fin_report_at',
    },
    {
      title: '财报日期',
      dataIndex: 'fin_report_at',
    },
    {
      title: '主营业务',
      dataIndex: 'main_biz',
    },
  ]

  const [buyLoading, setBuyLoading] = useState(false)
  const [buyData, setBuyData] = useState<BuyDataType[]>()

  interface BuyTableParams {
    ids: number[]
    amount: number
    start_at: string
    force_buy: boolean
  }

  const buyOnFinish = async (values: BuyTableParams) => {
    buyShowDrawer()
    const { start_at, amount } = values
    const params: BuyTableParams = {
      ids: selectedRowKeys as number[],
      amount: amount,
      start_at: '',
      force_buy: forceBuy,
    }
    if (start_at) {
      params.start_at = dayjs(start_at).format('YYYY-MM-DD 00:00:00')
    }
    setBuyLoading(true)
    try {
      const res = await http.post(`/v1/buy/trial`, { ...params })
      const { can_buy, can_not_buy } = res.data
      const data = []
      data.push(can_buy)
      data.push(can_not_buy)
      setBuyData(data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || '失败')
      }
    }
    setBuyLoading(false)
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const hasSelected = selectedRowKeys.length > 0

  interface BuyDataType {
    buy_type: string
    total_count: number
    total_amount: number
    total_buy_amount: number
    remain_amount_ttl: number
    per_amount: number
    list: BuyExpandedDataType[]
  }

  interface BuyExpandedDataType {
    code: string
    code_name: string
    entry_price: number
    qty: number
    buy_qty: number
    remain_qty: number
    buy_amount: number
    remain_amount: number
  }

  const expandedRowRender = (record: BuyDataType) => {
    const columns: TableColumnsType<BuyExpandedDataType> = [
      {
        title: '代码',
        dataIndex: 'code',
        align: 'right',
        render: (text) => <Text copyable={{ tooltips: false }}>{text}</Text>,
      },
      {
        title: '名称',
        dataIndex: 'code_name',
        align: 'right',
        render: (text) => <Text copyable={{ tooltips: false }}>{text}</Text>,
      },
      {
        title: '参考买入价',
        dataIndex: 'entry_price',
        align: 'right',
        sorter: (a, b) => a.entry_price - b.entry_price,
        render: (text) => <Text copyable={{ tooltips: false }}>{text}</Text>,
      },
      {
        title: '试算总数',
        dataIndex: 'qty',
        align: 'right',
        sorter: (a, b) => a.qty - b.qty,
      },
      {
        title: '买入数量',
        dataIndex: 'buy_qty',
        align: 'right',
        sorter: (a, b) => a.buy_qty - b.buy_qty,
        render: (text) => <Text copyable={{ tooltips: false }}>{text}</Text>,
      },
      {
        title: '买入金额',
        dataIndex: 'buy_amount',
        align: 'right',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.buy_amount - b.buy_amount,
      },
      {
        title: '剩余数量',
        dataIndex: 'remain_qty',
        align: 'right',
      },
      {
        title: '剩余金额',
        dataIndex: 'remain_amount',
        align: 'right',
      },
    ]

    return (
      <Table
        columns={columns}
        dataSource={record.list}
        pagination={false}
        rowKey="code"
        size="small"
      />
    )
  }

  const buyColumns: TableColumnsType<BuyDataType> = [
    { title: '类型', dataIndex: 'buy_type' },
    { title: '数量', dataIndex: 'total_count' },
    { title: '总金额', dataIndex: 'total_amount' },
    { title: '买入总金额', dataIndex: 'total_buy_amount' },
    { title: '剩余金额', dataIndex: 'remain_amount_ttl' },
    { title: '平均金额', dataIndex: 'per_amount' },
  ]

  const [buyopen, setBuyOpen] = useState(false)
  const buyShowDrawer = () => {
    setBuyOpen(true)
  }
  const buyOnClose = () => {
    setBuyOpen(false)
  }

  const [forceBuy, setForceBuy] = useState(false)

  const forceBuyOnChange = (checked: boolean) => {
    setForceBuy(checked)
  }

  return (
    <Spin spinning={loading}>
      <div className="tab-detail">
        <Flex
          justify="space-between"
          gap="small"
          vertical
        >
          <Card>
            <Descriptions items={descItems} />
          </Card>
          <Flex
            justify="space-between"
            gap="small"
            vertical
          >
            <Card>
              <Form
                onFinish={buyOnFinish}
                form={form}
                initialValues={{ amount: 500000, force_buy: false }}
              >
                <Row gutter={24}>
                  <Col span={4}>
                    <UFormItem
                      label="金额"
                      name="amount"
                    >
                      <InputNumber<number>
                        style={{ width: 150 }}
                        min={100000}
                        controls={false}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                        placeholder="请输入金额"
                      />
                    </UFormItem>
                  </Col>
                  <Col span={4}>
                    <UFormItem
                      label="开始时间"
                      name="start_at"
                    >
                      <DatePicker format={'YYYY-MM-DD'} />
                    </UFormItem>
                  </Col>
                  <Col span={3}>
                    <UFormItem
                      label="强买模式"
                      name="force_buy"
                    >
                      <Switch onChange={forceBuyOnChange} />
                    </UFormItem>
                  </Col>
                  <Col span={6}>
                    <UFormItem>
                      <Button
                        htmlType="button"
                        onClick={() => form.resetFields()}
                      >
                        重置
                      </Button>
                      <Button
                        type="primary"
                        style={{ marginLeft: '1rem' }}
                        htmlType="submit"
                      >
                        买入试算
                      </Button>
                      <span style={{ marginLeft: '1rem' }}>{hasSelected ? `已选中 ${selectedRowKeys.length} 项` : ''}</span>
                    </UFormItem>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Card>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={stock.list}
                pagination={false}
                size="small"
                rowSelection={{
                  selectedRowKeys,
                  onChange: onSelectChange,
                }}
              />
            </Card>
          </Flex>
        </Flex>
        <Drawer
          title="买入试算"
          placement="right"
          width={'100%'}
          closable={true}
          onClose={buyOnClose}
          open={buyopen}
          loading={buyLoading}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={buyOnClose}
              >
                保存
              </Button>
            </Space>
          }
        >
          <Table
            rowKey="buy_type"
            columns={buyColumns}
            expandable={{ expandedRowRender, defaultExpandedRowKeys: ['CanBuy', 'CanNotBuy'] }}
            dataSource={buyData}
            pagination={false}
            size="small"
          />
        </Drawer>
      </div>
    </Spin>
  )
}

export default TabDetail
