import KlineModal from '@/components/KlineModal'
import UFormItem from '@/components/UFormItem'
import { useStore } from '@/store'
import { http } from '@/utils'
import { AreaChartOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Flex, Form, Spin, Table, TableColumnsType, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { CandleTooltipCustomCallbackData, dispose, init } from 'klinecharts'
import { useEffect, useState } from 'react'
import '../index.css'

const { RangePicker } = DatePicker
const { Link } = Typography

interface AnalysisProps {
  batchId: number
}

interface AnalysisParams {
  batch_id: number
  date_range?: [Dayjs, Dayjs]
  date_begin_at: string
  date_end_at: string
}

interface ListDataType {
  id: number
  code: number
  code_name: string
  entry_price: number
  close_price: number
  close_roi: number
}

interface KlineParamsType {
  batchId: number
  code: number
  codeName: string
  dateStart: string
  dateEnd: string
}

const TabAnalysis: React.FC<AnalysisProps> = ({ batchId }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { batchStore } = useStore()
  const { sBeginAt, sEndAt } = batchStore

  const [params, setParams] = useState({
    batch_id: batchId,
    date_begin_at: sBeginAt,
    date_end_at: sEndAt,
  })
  const [listData, setListData] = useState<ListDataType[]>([])
  const ListColumns: TableColumnsType<ListDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 110,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: '证券代码',
      dataIndex: 'code',
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: '证券名称',
      dataIndex: 'code_name',
      render: (text, record: ListDataType) => {
        return (
          <Link onClick={() => showKlineModal(record)}>
            {text} <AreaChartOutlined />
          </Link>
        )
      },
    },
    {
      title: '买入价',
      dataIndex: 'entry_price',
      sorter: (a, b) => a.entry_price - b.entry_price,
    },
    {
      title: '卖出价格',
      dataIndex: 'close_price',
      sorter: (a, b) => a.close_price - b.close_price,
    },
    {
      title: '盈亏比例',
      dataIndex: 'close_roi',
      sorter: (a, b) => a.close_roi - b.close_roi,
      defaultSortOrder: 'descend',
      render: (text: number) => `${text.toFixed(2)}%`,
    },
  ]

  useEffect(() => {
    if (form.getFieldValue('date_range') === undefined) {
      form.setFieldsValue({
        date_range: [dayjs(sBeginAt, 'YYYY-MM-DD'), dayjs(sEndAt, 'YYYY-MM-DD')],
      })
    }

    const chart = init('chart')!
    chart.setStyles({
      yAxis: {
        // type: YAxisType.Percentage,
      },
      candle: {
        bar: {
          upColor: '#F92855',
          downColor: '#2DC08E',
          noChangeColor: '#888888',
          upBorderColor: '#F92855',
          downBorderColor: '#2DC08E',
          noChangeBorderColor: '#888888',
          upWickColor: '#F92855',
          downWickColor: '#2DC08E',
          noChangeWickColor: '#888888',
        },
        tooltip: {
          custom: (data: CandleTooltipCustomCallbackData) => {
            const { prev, current } = data
            const prevClose = prev?.close ?? current.open
            let change = 0
            if (current.open === 0) {
              change = current.close - current.open
            } else {
              change = current.close - prevClose
            }

            return [
              { title: 'time', value: '{time}' },
              { title: 'open', value: current.open.toFixed(2) },
              { title: 'high', value: current.high.toFixed(2) },
              { title: 'low', value: current.low.toFixed(2) },
              { title: 'close', value: current.close.toFixed(2) },
              {
                title: 'Change: ',
                value: {
                  text: `${change.toFixed(2)}%`,
                  color: change < 0 ? '#2DC08E' : '#F92855',
                },
              },
            ]
          },
        },
      },
    })

    const loadAnalysis = async () => {
      const res = await http.post(`/v1/batch/analysis`, { ...params })
      chart.applyNewData(res.data.overview)
      chart.zoomAtDataIndex(2.3, res.data.overview.length - 6)

      setListData(res.data.list)
      setLoading(false)
    }
    if (batchId) {
      setLoading(true)
      loadAnalysis()
    }
    return () => {
      dispose('chart')
    }
  }, [params])

  const onFinish = (values: AnalysisParams) => {
    const { date_range } = values
    // 修改params参数 触发接口再次发起
    if (date_range) {
      params.date_begin_at = date_range[0].format('YYYY-MM-DD 08:00:00')
      params.date_end_at = date_range[1].format('YYYY-MM-DD 08:00:00')
    }
    setParams({
      ...params,
    })
  }

  const onReset = () => {
    form.resetFields()
    setParams({
      batch_id: batchId,
      date_begin_at: sBeginAt,
      date_end_at: sEndAt,
    })
  }

  // kline modal start
  const [klineOpen, setKlineOpen] = useState(false)
  const [klineParams, setKlineParams] = useState<KlineParamsType>({} as KlineParamsType)
  const showKlineModal = (record: ListDataType | null) => {
    if (record) {
      setKlineParams({
        batchId: batchId,
        code: record.code,
        codeName: record.code_name,
        dateStart: dayjs(sBeginAt).subtract(365, 'day').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss'),
        dateEnd: dayjs().hour(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:ss'),
      })
    }
    setKlineOpen(true)
  }
  // kline modal end

  return (
    <div className="tab-analysis">
      <Flex
        justify="space-between"
        gap="small"
        vertical
      >
        <Card>
          <Form
            onFinish={onFinish}
            form={form}
          >
            <Flex
              justify="flex-start"
              gap={'1rem'}
            >
              <UFormItem
                label="时间范围"
                name="date_range"
              >
                {/* 传入locale属性 控制中文显示*/}
                <RangePicker></RangePicker>
              </UFormItem>
              <UFormItem>
                <Button
                  htmlType="button"
                  onClick={onReset}
                >
                  重置
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: '1rem' }}
                >
                  分析
                </Button>
              </UFormItem>
            </Flex>
          </Form>
        </Card>
        <Spin spinning={loading}>
          <Flex
            justify="space-between"
            gap="small"
            vertical
          >
            <Card>
              <div
                id="chart"
                style={{ width: '100%', height: 350 }}
              />
            </Card>
            <Card>
              <Table
                rowKey="id"
                columns={ListColumns}
                dataSource={listData}
                size="small"
                pagination={false}
                loading={loading}
                rowClassName={(record) => {
                  return record.close_roi > 0 ? 'profit-up' : 'profit-down'
                }}
              />
            </Card>
          </Flex>
        </Spin>
      </Flex>
      <KlineModal
        key={new Date().valueOf()}
        open={klineOpen}
        onCancel={() => setKlineOpen(false)}
        params={klineParams}
      ></KlineModal>
    </div>
  )
}

export default TabAnalysis
