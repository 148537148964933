import Routerx from '@/routerx'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Flex, Tabs, TabsProps, Typography } from 'antd'
import { Link, useParams } from 'react-router-dom'
import TabAnalysis from './TabAnalysis'
import TabDetail from './TabDetail'
const { Title } = Typography

const BatchDetail = () => {
  const params = useParams()
  const batchId = Number(params.id)

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: '详情',
      children: <TabDetail batchId={batchId} />,
    },
    {
      key: '2',
      label: '分析',
      children: <TabAnalysis batchId={batchId} />,
    },
  ]

  return (
    <div className="batch-detail">
      <Flex
        justify="space-between"
        gap="small"
        vertical
      >
        <Breadcrumb
          style={{ margin: '16px 0 5px 0' }}
          separator=">"
          items={[
            {
              title: '股池',
            },
            {
              title: <Link to="/batch">批次列表</Link>,
            },
            {
              title: '批次详情',
            },
          ]}
        />
        <Title
          level={4}
          style={{ margin: '5px 0 10px 0' }}
        >
          <Flex
            justify="flex-start"
            align="center"
            gap="small"
            vertical={false}
          >
            批次 ID: {batchId}
            <Button
              type="dashed"
              shape="circle"
              size="small"
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: '16px' }}
              onClick={() => Routerx.navigate(`/batch`)}
            ></Button>
          </Flex>
        </Title>
        <Tabs
          defaultActiveKey="1"
          items={tabItems}
        />
      </Flex>
    </div>
  )
}

export default BatchDetail
